var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v1 px-2"},[_c('div',{staticClass:"auth-inner py-2"},[_c('b-card',{staticStyle:{"border-radius":"16px"}},[_c('b-link',{staticClass:"brand-logo"},[_c('logo',{staticStyle:{"width":"300px"}})],1),_c('validation-observer',{ref:"loginValidation",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label-for":"email","label":"Email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-color",attrs:{"id":"email","name":"login-email","state":errors.length > 0 ? false : null,"placeholder":"Email","autofocus":""},on:{"focus":function($event){_vm.error = ''}},model:{value:(_vm.userEmail),callback:function ($$v) {_vm.userEmail=$$v},expression:"userEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Senha")])]),_c('validation-provider',{attrs:{"name":"Senha","rules":"required|password","vid":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge input-color",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false : null,"name":"login-password","placeholder":"············"},on:{"focus":function($event){_vm.error = ''}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',[_c('div',{staticClass:"d-flex justify-content-between"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Confirmar Senha")])]),_c('validation-provider',{attrs:{"name":"Senha","rules":"required|confirmed:Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid' : null},[_c('b-form-input',{staticClass:"form-control-merge input-color",attrs:{"id":"password","type":_vm.passwordFieldType2,"state":errors.length > 0 ? false : null,"name":"login-password","placeholder":"············"},on:{"focus":function($event){_vm.error = ''}},model:{value:(_vm.cPassword),callback:function ($$v) {_vm.cPassword=$$v},expression:"cPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon2},on:{"click":_vm.togglePassword2Visibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),(_vm.error)?_c('div',{staticClass:"text-danger text-center mb-1 mt-1"},[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),_c('b-button',{attrs:{"variant":"primary","type":"submit","block":"","disabled":invalid},on:{"click":_vm.validationForm}},[_vm._v(" Cadastrar ")])],1)]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }